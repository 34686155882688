table {
  text-align: center;
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  border: 3px solid #ddd;
  width: 80%;
}

td, th {
  border: 1px solid #ddd;
  padding: 8px;
}
